<template>
  <vs-popup
    ref="feedbackModal"
    :title="$t('Title')"
    :active.sync="showModal">
    <transition name="zoom-fade">
      <div v-if="showModal">
        <p class="text-center">
          {{ $t('Subtitle') }}
        </p>

        <emojis-rating
          ref="emojisRating"
          v-model="model.score"
          class="mt-3"/>

        <transition name="zoom-fade">
          <feedback-modal-select-type
            v-if="model.score !== null"
            v-model="model.type"
            class="mt-base"/>
        </transition>

        <transition name="zoom-fade">
          <feedback-modal-comments
            v-if="model.type !== $enums.AppFeedbackType.NO_COMMENTS"
            ref="comments"
            v-model="model.description"
            class="mt-base w-full"/>
        </transition>

        <transition name="zoom-fade">
          <upload-file
            v-if="model.type !== $enums.AppFeedbackType.NO_COMMENTS"
            ref="uploadFile"
            class="mt-base"
            :accept-ext="[
              '.png', '.jpg', '.jpeg', '.gif',
              '.mp4','.wmv', '.avi', '.mkv', '.webm', '.flv'
            ]"
            :max-file-size="20480"
            @file="(file) => this.file = file"/>
        </transition>

        <upload-loader
          v-show="saving"
          :text="$t('$General.Saving')"/>

        <vs-divider/>

        <div
          v-if="model.score !== null"
          class="flex justify-end">
          <vs-button
            v-avoid-key-tab-event
            color="primary"
            @click="sendFeedback">
            {{ $t('$General.Submit') }}
          </vs-button>
        </div>
      </div>
    </transition>
  </vs-popup>
</template>

<script>
import { mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

// Components
import EmojisRating from '@/components/EmojisRating.vue';
import UploadFile from '@/views/modules/_components/UploadFile.vue';
import FeedbackModalSelectType from '@/layouts/components/feedback/TheFeedbackModalSelectType.vue';
import FeedbackModalComments from '@/layouts/components/feedback/TheFeedbackModalComments.vue';
import UploadLoader from '@/views/modules/_components/UploadLoader.vue';

// Services
import { uploadFileS3, getPreSignedUrlS3 } from '@/aws';
import feedbackService from '@/api/modules/feedback.service';

// Utils
import { getExtFromFileType } from '@/util';

/**
 * Feedback button
 *
 * @module layouts/components/FeedbackButton.vue
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {number | null} value - indicate if show or no the modal
 * @vue-data {boolean} showModal - show or no the feedback modal
 * @vue-data {Object} model - model to save
 * @vue-data {File | null} file - file to upload with feedback
 * @vue-data {boolean} saving - indicate if feedback is saving or no
 * @vue-event {void} sendFeedback - send the feedback
 * @vue-event {void} uploadFile - upload feedback file
 */
export default {
  name: 'TheFeedbackModal',
  i18n: {
    messages: {
      en: {
        Title: 'Share your experience with us',
        Subtitle: 'How satisfied are you with GrapeSend today?',
        $SentNotification: {
          Title: 'Feedback sent',
          Msg: 'Thank you! Your feedback has been successfully submitted.',
        },
      },
    },
  },
  components: {
    FeedbackModalSelectType,
    FeedbackModalComments,
    EmojisRating,
    UploadFile,
    UploadLoader,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showModal: this.value,
      model: {
        score: null,
        type: this.$enums.AppFeedbackType.NO_COMMENTS,
        description: '',
        url: '',
      },
      file: null,
      saving: false,
    };
  },
  computed: {
    ...mapGetters({
      basePathUserToUploadResources: 'auth/basePathUserToUploadResources',
    }),
  },
  watch: {
    value(value) {
      this.showModal = value;
    },
    showModal(value) {
      this.model = {
        score: null,
        type: this.$enums.AppFeedbackType.NO_COMMENTS,
        description: '',
        url: '',
      };

      this.$emit('input', value);
    },
  },
  methods: {
    async sendFeedback() {
      const commentsValid = this.model.type !== this.$enums.AppFeedbackType.NO_COMMENTS
        ? await this.$refs.comments.$validator.validateAll()
        : true;
      const uploadValid = this.model.type !== this.$enums.AppFeedbackType.NO_COMMENTS
        ? await this.$refs.uploadFile.$validator.validateAll()
        : true;

      if (commentsValid && uploadValid) {
        try {
          this.saving = true;
          this.model.url = await this.uploadFile(this.file);
          await feedbackService.create(this.model);
          this.saving = false;

          this.$showSuccessActionNotification({
            title: this.$t('$SentNotification.Title'),
            text: this.$t('$SentNotification.Msg'),
          });

          this.showModal = false;
        } catch (e) {
          this.saving = false;
          this.showModal = false;
        }
      }
    },
    async uploadFile(file) {
      if (file && file.type) {
        const ext = getExtFromFileType(file.type);
        const bucketPath = `${this.basePathUserToUploadResources}/feedback/${uuidv4()}.${ext}`;

        const respS3 = await uploadFileS3(file, bucketPath);
        return getPreSignedUrlS3(respS3.Key);
      }

      return '';
    },
  },
};
</script>
